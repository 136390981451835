<template>
	<div class="insightBox">
		<div class="leftChildNav" :class="showLeft ? '' : 'hide'">
			<div class="topTitle">{{ title[activeR] }}</div>
			<div class="fixedLeft" @click="changeFixed">
				<img v-if="fixedLeft" src="../../assets/image/fixed_active.png" alt />
				<img v-else src="../../assets/image/fixed.png" alt />
			</div>
			<Menu :active-name="activeMenu" @on-select="selectRouter" :accordion="true" @on-open-change="submenuSelect">
				<template v-for="(item, index) in route[activeR]">
					<Submenu :name="'submenu' + (item.path || item.url)" v-if="item.children && item.children.length" :key="index">
						<template slot="title" class="topNav">
							<!-- <Icon :type="item.icon"
								v-if="item.title.indexOf('政策')==-1&&item.title.indexOf('国际')==-1&&item.title.indexOf('搜索')==-1&&item.title.indexOf('企业大数据')==-1&&item.title.indexOf('企业征信')==-1&&item.title.indexOf('舆情热点')==-1"
								size="20" /> -->
							<Icon :custom="item.icon" size="20" />

							{{ item.title }}
						</template>
						<template v-for="(item, index) in item.children">
							<MenuItem :key="index" v-if="!item.children" :name="item.path || item.url">{{ item.title }}
							</MenuItem>
							<Submenu :key="index" v-else :name="item.path || item.url">
								<template slot="title">
									{{ item.title }}
								</template>
								<MenuItem :key="index" v-for="(item, index) in item.children" :name="item.path || item.url">{{ item.title
}}</MenuItem>
							</Submenu>
						</template>
					</Submenu>
					<MenuItem v-else :key="index" :name="item.path || item.url">
					<!-- 					<Icon :type="item.icon"
						v-if="item.title.indexOf('政策')==-1&&item.title.indexOf('国际')==-1&&item.title.indexOf('搜索')==-1&&item.title.indexOf('企业大数据')==-1&&item.title.indexOf('企业征信')==-1&&item.title.indexOf('舆情热点')==-1"
						size="20" /> -->
					<Icon :custom="item.icon" size="20" />

					{{ item.title }}
					</MenuItem>
				</template>
			</Menu>
		</div>
		<div class="rightContent">
			<img class="openNav" v-if="showLeft" src="../../assets/image/shouqi.png" @click="changeState({
	prop: 'showLeft',
	value: false
})" />
			<img class="closeNav" v-if="!showLeft" @click="changeState({
	prop: 'showLeft',
	value: true
})" src="../../assets/image/zhankai.png" />

			<transition name="router-move">
				<iframe v-if="showIframe" :src="iframeHref" width="100%" height="100%" class="iframe_box"></iframe>
				<router-view v-else />
			</transition>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapMutations
} from 'vuex'
export default {
	name: 'home',
	data() {
		return {
			routerChildren: [],
			showIframe: false,
			iframeHref: '',
			activeR: 'Customerorder',
			title: {
				Customerorder: '定制化',
			},
			route: {
				Customerorder: [{
					path: '/customerorder/customerorder',
					title: '定制化',
					icon: "iconfont icon-dingzhi",
					children: [
						// {
						// 	path: "/customerorder/customerorder",
						// 	title: "定制化首页",
						// },
						// {
						// 	path: "/customerorder/interestspackage",
						// 	title: "我的权益",
						// },
					],
				},],
			}
		};
	},
	computed: {
		activeMenu() {
			let route = this.$route;
			let path = route.path;
			let meta = route.meta;
			if (meta && meta.from) {
				path = meta.from;
			}
			// let activeMenu = this.route(this.activeR);
			return path;
		},
		...mapState(['actionRouter', 'iframeUrl', 'showLeft', "fixedLeft"])
	},
	watch: {
		$route(newVal, oldVal) {
			if (newVal.path !== oldVal.path) {
				this.showIframe = false;
				this.iframeHref = '';
				this.changeLeft(newVal);
			}
		}
	},
	mounted() {
		this.changeLeft(this.$route);
		this.$store.dispatch("initCustomizationData");
	},
	methods: {
		...mapMutations(['changeState', "changeFixed"]),
		selectRouter(data) {
			if (this.$route.path === data) return;
			if (data.indexOf('http') > -1) {
				this.changeState({
					prop: 'iframeUrl',
					value: data
				})
				localStorage.setItem('iframeUrl', data)
				this.$router.push('/Insight/iframe')
				this.showIframe = true;
				this.iframeHref = data;
			} else if (data.indexOf('noPage') > -1) {
				this.showIframe = false;
				this.iframeHref = '';
				return
			} else {
				this.showIframe = false;
				this.iframeHref = '';
				this.$router.push(data).catch(err => {
				});
			}
		},
		submenuSelect(item) {
			if (item.length == 0) return
			const data = item.pop().replace('submenu', '')
			if (data.indexOf('http') > -1) {
				this.showIframe = true;
				this.iframeHref = data;
				this.changeState({
					prop: 'iframeUrl',
					value: data
				})
				localStorage.setItem('iframeUrl', data)
				this.$router.push('/Insight/iframe')
				return
			}
			data && data != 'undefined' && this.$router.push(data)
			this.showIframe = false;
			this.iframeHref = '';
		},
		changeLeft(router) {
			let path = router.query.rPath ? router.query.rPath : router.path
			let route = {
				Customerorder: [{
					path: '/customerorder',
					title: '定制化',
					icon: "md-arrow-dropdown",
					children: [{
						path: "/customerorder/customerOrder",
						title: "定制化首页",
					},
					{
						path: "/customerorder/interestspackage",
						title: "我的权益",
					},
					],
				},],
			}
			// 用path来判断属于哪个二级路由
			if (path.indexOf('/customerorder') > -1) {
				this.activeR = 'Customerorder';
			}
			// if (this.actionRouter.length) {
			//   this.route[this.activeR] = [...this.actionRouter, ...route[this.activeR]]
			// }
		}
	},
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";

// .router-move-enter {
//     transform: translate3d(-100%, 0, 0);
// }
// .router-move-leave-to {
//     transform: translate3d(-100%, 0, 0);
// }
// .router-move-enter-active,
// .router-move-leave-active {
//     transition: transform 0.3s;
// }
.iframe_box {
	border: 0;
}

.insightBox {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	background: #fff;
	display: flex;




}
</style>
