var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insightBox"},[_c('div',{staticClass:"leftChildNav",class:_vm.showLeft ? '' : 'hide'},[_c('div',{staticClass:"topTitle"},[_vm._v(_vm._s(_vm.title[_vm.activeR]))]),_c('div',{staticClass:"fixedLeft",on:{"click":_vm.changeFixed}},[(_vm.fixedLeft)?_c('img',{attrs:{"src":require("../../assets/image/fixed_active.png"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/image/fixed.png"),"alt":""}})]),_c('Menu',{attrs:{"active-name":_vm.activeMenu,"accordion":true},on:{"on-select":_vm.selectRouter,"on-open-change":_vm.submenuSelect}},[_vm._l((_vm.route[_vm.activeR]),function(item,index){return [(item.children && item.children.length)?_c('Submenu',{key:index,attrs:{"name":'submenu' + (item.path || item.url)}},[_c('template',{staticClass:"topNav",slot:"title"},[_c('Icon',{attrs:{"custom":item.icon,"size":"20"}}),_vm._v(" "+_vm._s(item.title)+" ")],1),_vm._l((item.children),function(item,index){return [(!item.children)?_c('MenuItem',{key:index,attrs:{"name":item.path || item.url}},[_vm._v(_vm._s(item.title)+" ")]):_c('Submenu',{key:index,attrs:{"name":item.path || item.url}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.children),function(item,index){return _c('MenuItem',{key:index,attrs:{"name":item.path || item.url}},[_vm._v(_vm._s(item.title))])})],2)]})],2):_c('MenuItem',{key:index,attrs:{"name":item.path || item.url}},[_c('Icon',{attrs:{"custom":item.icon,"size":"20"}}),_vm._v(" "+_vm._s(item.title)+" ")],1)]})],2)],1),_c('div',{staticClass:"rightContent"},[(_vm.showLeft)?_c('img',{staticClass:"openNav",attrs:{"src":require("../../assets/image/shouqi.png")},on:{"click":function($event){return _vm.changeState({
	prop: 'showLeft',
	value: false
})}}}):_vm._e(),(!_vm.showLeft)?_c('img',{staticClass:"closeNav",attrs:{"src":require("../../assets/image/zhankai.png")},on:{"click":function($event){return _vm.changeState({
	prop: 'showLeft',
	value: true
})}}}):_vm._e(),_c('transition',{attrs:{"name":"router-move"}},[(_vm.showIframe)?_c('iframe',{staticClass:"iframe_box",attrs:{"src":_vm.iframeHref,"width":"100%","height":"100%"}}):_c('router-view')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }